<template>
  <b-modal
    id="view-invoice"
    centered
    size="lg"
    :title="'View Invoice # ' + invoiceNumber"
    hide-footer
  >
    <div class="d-flex align-items-center justify-content-center" style="height: 19rem;" v-if="intialLoading" >
      <b-spinner style="width: 5rem; height: 5rem; font-size:2rem;" variant="primary" />
    </div>
    <b-row v-else>
      <b-col md="4">
        <div>
          <b-form-group label="Invoice Number:">
              <strong>{{ invoice.invoice_number }}</strong>
          </b-form-group>
        </div>
      </b-col>

      <b-col md="4">
        <b-form-group label="Invoice Status:">
          <strong
            v-if="invoice.status == 'partial'"
            class="text-capitalize text-warning"
          >
            Partially Paid
          </strong>

          <strong
            v-else-if="invoice.status == 'paid'"
            variant=""
            class="text-capitalize text-success"
          >
            Paid
          </strong>

          <strong
            v-else-if="invoice.status == 'unpaid'"
            class="text-capitalize text-danger"
          >
            Unpaid
          </strong>

          <strong
            v-else
            class="text-capitalize text-primary"
          >
            {{ invoice.status }}
          </strong>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group label="Invoice Type:">
          <strong>{{ invoice.mode }}</strong>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group label="Amount Paid:">
            <strong v-if="invoice.invoice_transactions_paid <= 0">
              -
            </strong>
            <strong v-else>
              KSH {{ invoice.invoice_transactions_paid }}
            </strong>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group label="Amount Due:">
          <strong v-if="invoice.invoice_transactions_paid >= invoice.amount">
            -
          </strong>
          <strong v-else>
            KSH {{invoice.amount - invoice.invoice_transactions_paid}}
          </strong>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group label="Total Amount:">
          <strong>{{ invoice.amount }}</strong>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group label="Invoice Description:">
          <strong>{{ invoice.message }}</strong>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group label="Due Date:">
          <strong>{{ invoice.format_created_at }}</strong>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group label="Client:">
          <strong>{{ invoice.first_name }} {{ invoice.last_name }}</strong>
        </b-form-group>
      </b-col>

    </b-row>
  </b-modal>
</template>

<script>
import { 
  BModal,
  BRow,
  BCol,
  BTable,
  BButton,
  BSpinner,
  BFormGroup,

} from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";


export default {
  components: {
    BModal,
    BRow,
    BCol,
    BTable,
    BButton,
    BSpinner,
    BFormGroup,
  },
  props: ["id", "invoiceNumber"],
  data(){
    const { appUrl } = $themeConfig.app;
    return{
      intialLoading: false,
      invoice: {},
      appUrl,
    };
  },
  methods:{
    hideModal(){
      this.$bvModal.hide('view-user');
    },
  },
  mounted(){
    this.$root.$on('bv::modal::show', (_, modalId) => {
      if(modalId == "view-invoice"){
        if(this.id && this.id != null && this.id != undefined){
          this.intialLoading = true;
          this.$http
            .get("/invoices/"+ this.id +"/show")
            .then((response) => {
              this.invoice = response.data.data;
              this.intialLoading = false;
            });
        }
      }
    });
  }
};
</script>